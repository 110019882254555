<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <b-form @submit.prevent="save_user" @reset.prevent="cancel">
        <b-row>
          <b-col col-sm="12" col-md="3">
            <b-form-group label-for="name" validated>
              <b-form-input placeholder="Hesabınıza kısa ad verin" type="text" class="form-control-warning" id="name" v-model="new_account_name" required></b-form-input>
              <b-form-valid-feedback></b-form-valid-feedback>
              <b-form-invalid-feedback>Boş bırakılamaz</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col col-sm="12" col-md="4">
            <b-form-group label-for="username" validated>
              <b-form-input placeholder="Hesabınızın kullanıcı adı" type="text" class="form-control-warning" id="username" v-model="new_account_username" required></b-form-input>
              <b-form-valid-feedback></b-form-valid-feedback>
              <b-form-invalid-feedback>Boş bırakılamaz</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col col-sm="12" col-md="4">
            <b-form-group label-for="password" validated>
              <b-form-input placeholder="Hesabınızın şifresi" type="password" class="form-control-warning" id="password" v-model="new_account_password" required></b-form-input>
              <b-form-valid-feedback></b-form-valid-feedback>
              <b-form-invalid-feedback>Boş bırakılamaz</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col col-sm="12" col-md="1">
            <b-button size="sm" variant="success" @click="check_for_account_list()"><i class="fa fa-plus"></i></b-button>
          </b-col>
        </b-row>
      </b-form>
      <template v-for="(account, account_ind) in user.account_list">
        <b-row>
          <b-col cols="5">
            <b-form-input type="text" v-model="account.name"></b-form-input>
          </b-col>
          <b-col cols="5">{{ account.username }}</b-col>
          <b-col cols="2">
            <b-button size="sm" variant="danger" @click="user.account_list.splice(account_ind, 1)"><i class="fa fa-trash"></i></b-button>
          </b-col>
        </b-row>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  default as Modal
} from '@/components/widgets/Modal';
import Vue from 'vue';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from 'wisdom-config';
import {
  default as router
} from '@/router';
import UserService from '@/services/users';
import vSelect from 'vue-select';
import { Switch as cSwitch } from '@coreui/vue'

export default {
  name: 'UserAddEdit',
  computed: {
    ...mapGetters({})
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      new_account_name: '',
      new_account_username: '',
      new_account_password: ''

    };
  },
  created: function() {},
  methods: {
    check_for_account_list: function() {
      UserService.check_for_account_list({ 'account_username': this.new_account_username, 'account_password': this.new_account_password })
        .then(resp => {
          if (resp.data.status === 'success') {
            let main_account_data = { 'main_account': 1, 'name': this.user.first_name + ' ' + this.user.last_name, 'user_id': 'user_' + this.user.username, 'username': this.user.username, 'password': this.user.password };
            let new_account_data = { 'main_account': 0, 'name': this.new_account_name, 'user_id': 'user_' + this.new_account_username, 'username': this.new_account_username, 'password': resp.data.result.password };
            let is_update = false;
            if (!this.user.account_list) {
              this.user.account_list = [];
            }
            for (let i in this.user.account_list) {
              if (this.new_account_username === this.user.account_list[i].username) {
                is_update = true;
                this.user.account_list[i] = JSON.parse(JSON.stringify(new_account_data));
                break;
              }
            }
            if (!is_update) {
              this.user.account_list.push(new_account_data);
            }
            // let new_account_list = [];
            // new_account_list.push(main_account_data);
            // for (let k in this.user.account_list) {
            //   new_account_list.push(this.user.account_list[k]);
            // }
            // localStorage.setItem('account_list', JSON.stringify(new_account_list));
            // this.$store.commit('account_list', new_account_list);
            this.$forceUpdate();
            // empty form
            this.new_account_name = '';
            this.new_account_username = '';
            this.new_account_password = '';

            alert('Kullanıcı ekleme işlemi başarıyla tamamlandı');
          } else {
            // empty form
            this.new_account_name = '';
            this.new_account_username = '';
            this.new_account_password = '';
            alert('Lütfen girdiğiniz bilgileri tekrar kontrol ediniz');
          }
        });
    }
  },
  watch: {},
  components: {
    vSelect,
    cSwitch
  }
};

</script>

<style type="text/css">
.nav-item-selected {
  background: #d6f2b0;
}

.nav-item-not-selected {}

</style>

